body {
    position: relative;
    overflow-x: hidden;
    font-family: "Work Sans", sans-serif !important;
  }
  
  .css-t3ipsp-control {
    border-radius: 8px;
    border-color: #f38e65 !important;
    box-shadow: 0 0 0 0.25rem rgba(226, 106, 56, 0.4) !important;
  }
  
  .form-control:focus,
  .form-select:focus {
    border-color: #f38e65 !important;
    box-shadow: 0 0 0 0.25rem rgba(226, 106, 56, 0.4) !important;
  }
  
  button {
    &:focus {
      box-shadow: none !important;
      outline: none;
    }
  }
  
  .orange-text {
    font-weight: 600;
    color: #e26a38;
    text-decoration: none;
  
    &:hover {
      color: #ee8153;
    }
  }
  
  .orange-button {
    background-color: #e26a38 !important;
    border-color: #e26a38 !important;
  }
  
  .add-more-button {
    border: 1px solid #e26a38 !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    color: #e26a38 !important;
  
    span {
      background-color: #e26a38;
      color: #fff;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
  }
  
  input,
  textarea {
    border-radius: 8px !important;
  }
  
  .css-13cymwt-control {
    border-radius: 8px !important;
  }
  
  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: rgba(204, 204, 204, 0.12);
    color: var(--bs-table-hover-color);
  }
  
  .dropdown-menu {
    .dropdown-item {
      &:focus,
      &:hover {
        color: #fff;
        background-color: #e26a38;
  
        svg {
          color: #fff;
        }
      }
    }
  }
  
  // Header
  .front-navbar {
    position: relative !important;
    width: 100%;
    top: 0;
    z-index: 20;
    transition: all 2s ease;
    background-color: #fefefe;
    box-shadow: 10px 0px 10px 5px rgba(191, 191, 191, 0.25);
    padding: 12px 0;
    height: 82px;
  
    @media screen and (max-width: 767px) {
      height: auto;
    }
  
    &.fixed-navbar {
      position: fixed !important;
      top: 0;
      width: 100%;
      opacity: 1;
      z-index: 5;
      background-color: #fefefe;
      box-shadow: 10px 0px 10px 5px rgba(191, 191, 191, 0.25);
      // @media screen and (max-width: 767px) {
      //     padding: 8px 15px 8px 0;
      //     top: 20px;
      // }
    }
  
    .nav-container {
      .nav-logo {
        display: inline-block;
        cursor: pointer;
  
        img {
          height: 60px;
  
          @media screen and (max-width: 767px) {
            height: 46px;
          }
        }
      }
  
      .nav-menu {
        display: flex;
        list-style: none;
        text-align: center;
        margin-bottom: 0;
        width: 88%;
        width: auto;
        float: right;
  
        @media screen and (max-width: 767px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid #fff;
          position: absolute;
          top: 70px;
          left: -110%;
          opacity: 1;
          transition: all 0.5s ease;
  
          &.active {
            left: 0px;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            // padding-bottom: 30px;
            padding-left: 0;
            text-align: left;
            background-color: #fff !important;
            height: 100vh;
            border-top: #000 solid 1px;
          }
        }
  
        ul {
          list-style: none;
          margin-left: auto;
          display: flex;
          margin-bottom: 0;
  
          @media screen and (max-width: 767px) {
            display: block;
            height: 100%;
            margin-left: 0;
            padding: 0;
          }
  
          .nav-item {
            line-height: 50px;
            color: #fff;
            font-weight: 500;
            padding: 0.5rem 1rem;
            position: relative;
  
            @media screen and (max-width: 767px) {
              line-height: 20px;
            }
  
            &::after {
              content: "";
              border-right: #000 solid 2px;
              right: 0;
              position: absolute;
              height: 14px;
              margin: auto;
              top: 50%;
              transform: translate(-50%, -50%);
  
              @media screen and (max-width: 767px) {
                display: none;
              }
            }
  
            &:last-child,
            &:nth-last-child(-n + 2) {
              &::after {
                border-right: none;
              }
            }
  
            .nav-links {
              color: #00344b;
              text-decoration: none;
              padding: 0.5rem 0.2rem;
              height: 100%;
  
              &.active {
                color: #e26a38;
                border-bottom: #e26a38 solid 2px;
              }
  
              &:hover {
                color: #e26a38;
              }
            }
  
            .btn {
              background: #e26a38;
              border-color: #e26a38;
  
              &:focus {
                border-color: #6c3014 !important;
                box-shadow: 0 0 0 0.25rem rgb(153 99 60 / 50%);
              }
            }
          }
  
          .btn-group {
            display: flex;
  
            @media screen and (max-width: 767px) {
              display: block;
              padding-left: 20px;
              margin-top: 20px;
            }
  
            .toggle {
              background-color: transparent;
              border: none;
              padding: 0;
              margin: auto;
              height: auto;
  
              &:focus {
                box-shadow: none;
                background-color: transparent !important;
              }
  
              svg {
                color: #00344b;
              }
            }
          }
        }
      }
  
      .nav-icon {
        display: none;
  
        @media screen and (max-width: 767px) {
          display: inline-block;
          float: right;
          margin-left: auto;
          font-size: 24px;
        }
      }
    }
  }
  
  .homepage_banner {
    background: url(../../../public/assets/banner.png) center top no-repeat;
    background-size: cover;
    color: #fff;
    padding: 50px 0;
    position: relative;
    height: 100vh;
    // margin-top: 25px;
  
    &.secondary-homepage_banner {
      @media screen and (min-width: 1700px) {
        height: 80vh;
      }
  
      .react-tel-input .form-control {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }
  
    &::after {
      content: "";
      position: absolute;
      top: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
    }
  
    .homepage-content {
      position: relative;
      z-index: 1;
      margin: auto;
  
      h1 {
        font-family: "Beatrice";
        font-weight: bold;
      }
  
      hr {
        border-bottom: #fff solid 1px;
        opacity: 1;
        width: 85%;
      }
  
      .stepping {
        position: relative;
  
        .pBar {
          position: absolute;
          border-style: dashed;
          height: 100%;
          left: 6%;
          z-index: -1;
  
          @media screen and (max-width: 767px) {
            left: 34px;
          }
        }
  
        .node {
          display: flex;
          margin: 52px 0;
  
          .stepping-icon {
            background-color: #e26a38;
            // outline: #E26A38 solid 5px;
            border-radius: 50%;
            // border: #FFFFFF solid 1px;
            width: 80px;
            height: 80px;
            display: flex;
  
            svg {
              margin: auto;
              font-size: 24px;
            }
          }
  
          .stepping-details {
            width: 71%;
            margin-left: 6%;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  
    .login {
      z-index: 1;
      margin-top: 50px;
  
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
  
      &.login_bg {
        &::before {
          content: "";
          background: url(../../../public/assets/leaf.png) left top no-repeat;
          width: 58px;
          height: 58px;
          position: absolute;
          z-index: -1;
        }
      }
  
      .card-body {
        padding: 30px;
  
        @media screen and (min-width: 768px) and (max-width: 991px) {
          padding: 30px 15px;
        }
  
        @media screen and (max-width: 767px) {
          padding: 30px 15px;
        }
  
        h1 {
          color: #e26a38;
        }
  
        h2 {
          color: #000;
        }
  
        p {
          color: #000;
        }
  
        h5 {
          color: #000;
          font-weight: 700;
  
          span {
            color: #e26a38;
          }
        }
  
        h6 {
          color: #e26a38;
          font-weight: 600;
        }
  
        .login-form {
          width: 85%;
          margin: auto;
  
          @media screen and (max-width: 767px) {
            width: 100%;
          }
  
          .form-group {
            position: relative;
  
            .form-control {
              // padding-left: 35px;
            }
  
            .icon {
              position: absolute;
              color: #000;
              top: 50%;
              transform: translate(-50%, -50%);
              left: 4%;
  
              &.key {
                transform: translate(-50%, -50%) rotate(145deg);
              }
            }
          }
        }
  
        .signup-form {
          .react-tel-input .form-control {
            width: 100%;
          }
  
          .signup-select__control {
            height: 39px;
            border-radius: 8px;
  
            .signup-select__value-container {
              margin-top: -6px;
            }
          }
  
          .signup-select__menu {
            color: hsl(0, 0%, 20%);
          }
  
          .select__menu {
            color: hsl(0, 0%, 20%);
          }
        }
  
        .form-group {
          position: relative;
  
          .form-control {
            height: 39px;
          }
  
          span {
            position: absolute;
            right: 30px;
            top: 8px;
          }
        }
  
        .submit-button {
          width: 100%;
          background: #e26a38;
          border: none;
          font-weight: 500;
        }
  
        .forgot {
          font-weight: 600;
          color: #e26a38;
          text-decoration: none;
        }
      }
    }
  }
  @media screen and (min-width: 1920px) and (min-height: 1080px) {
    /* Adjust styles for the FAQs section on a 24-inch screen here */
    .userAdminAside .nav-link {
      font-size: 18px;
      /* Add any other styles specific to the FAQs section on a 24-inch screen */
    }
  }
  .update_donor_details {
    .select-customise {
      // select {
      //     color: #8c8c8c;
      // }
      // select,
      // select option {
      // color: #000000;
      // }
  
      select:invalid,
      select option[value=""] {
        color: #999999;
      }
    }
  }
  
  // section banner
  
  // donor-movement
  
  .donor-movement {
    padding: 60px 0;
  
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }
  
    .donor-content {
      width: 95%;
  
      h3 {
        color: #000;
        font-weight: 700;
  
        span {
          color: #e26a38;
        }
      }
  
      hr {
        width: 60px;
        border-bottom: #e26a38 solid 2px;
        opacity: 1;
        background-color: #e26a38;
        border-top: #e26a38 solid 1px;
      }
  
      ul {
        li {
          &::marker {
            color: #e26a38;
            font-size: 24px;
          }
        }
      }
    }
  
    .donor-movement_image {
      height: 736px;
      background: url(../../../public/assets/homepage_image.jpeg) left top
        no-repeat;
      position: relative;
      background-position: center;
  
      &::before {
        background: rgba(0, 181, 116, 0.12);
        content: "";
        position: absolute;
        bottom: 1%;
        left: -4%;
        width: 80%;
        height: 80%;
        z-index: -1;
      }
  
      &::after {
        content: "";
        background: url(../../../public/assets/leaf.png) left top no-repeat;
        width: 58px;
        height: 58px;
        position: absolute;
        bottom: -1%;
        left: -8%;
        transform: rotate(-70deg);
      }
    }
  }
  
  // donor-movement
  
  // newsLetter
  
  .newsLetter {
    padding: 60px 0;
    background-color: #f0eeef;
    // background-image: url(../../../public/assets/colorful-letters-forming-word-donate.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }
  
    .newsLetter_content {
      width: 40%;
      margin: auto;
  
      @media screen and (max-width: 991px) {
        width: 51%;
      }
  
      @media screen and (max-width: 767px) {
        width: 100%;
      }
  
      h3 {
        color: #000;
        font-weight: 700;
  
        span {
          color: #e26a38;
        }
      }
  
      hr {
        border-top: #e26a38 solid 4px;
        height: 2px;
        opacity: 1;
        background-color: #e26a38;
        width: 10%;
        margin: auto;
        margin-bottom: 40px;
      }
  
      .form-group {
        .form-control {
          height: 39px;
        }
      }
  
      .submit-button {
        background: #e26a38;
        border: none;
        font-weight: 500;
        padding: 10px 40px;
      }
    }
  }
  
  // newsLetter
  
  // userAdminPanel
  .userAdminPanel {
    font-family: "Work Sans", sans-serif !important;
  
    .collect_donation {
      width: 55%;
      margin: 30px 0 10px 0;
  
      iframe {
        height: 600px;
  
        @media screen and (min-width: 992px) {
          height: 675px;
        }
  
        @media screen and (max-width: 767px) {
          height: 745px;
        }
      }
    }
  
    .donation_type {
      margin: 39px 20px 0px 40px;
      width: 27%;
    }
  
    .contribute-dmf-bottom {
      margin-top: 40px;
  
      .collect_donation {
        width: 100%;
        margin: 0;
      }
  
      .donation_type {
        margin: 0;
        width: 100%;
      }
    }
  
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .customised-row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    @media screen and (max-width: 767px) {
      // padding: 0 15px;
    }
  
    .userAdminAside {
      background: rgba(128, 35, 103, 0.06);
      height: 100%;
      border-radius: 0;
      box-shadow: none;
  
      .user_details {
        text-align: center;
        margin-bottom: 20px;
  
        img {
          height: 80px;
          border-radius: 50%;
          margin-bottom: 20px;
        }
  
        h5 {
          font-family: "Work Sans";
          font-weight: bold;
        }
  
        p {
          color: #8f8c8c;
          font-size: 14px;
        }
      }
  
      .nav {
        margin-left: auto;
        width: 85%;
        border: none;
  
        @media screen and (max-width: 1204px) {
          width: 100%;
        }
  
        .nav-item {
          line-height: 50px;
          color: #fff;
          font-weight: 500;
          padding: 0;
          width: inherit;
  
          .nav-link {
            padding-top: 0;
            padding-bottom: 0;
            font-family: "Work Sans", sans-serif;
            text-align: left;
            font-weight: 400;
            color: #272727;
            border: none;
            font-size: 14px;
  
            @media screen and (min-width: 768px) and (max-width: 1200px) {
              font-size: 11px;
              // padding: 6px; remove this for sidebar padding
            }
  
            &.active {
              border-radius: 30px 0 0 30px;
              color: #802367;
  
              @media screen and (max-width: 1024px) {
                border-radius: 0;
              }
            }
  
            svg {
              margin-right: 10px;
              color: #c351a5;
              width: 1.2rem;
              height: 1.2rem;
  
              @media screen and (min-width: 768px) and (max-width: 991px) {
                margin-right: 1px;
                width: 0.8rem;
                height: 0.8rem;
              }
            }
  
            &.active {
              svg {
                color: #802367;
              }
            }
          }
        }
      }
    }
  
    .userAdminArticle {
      width: 98%;
      box-shadow: none !important;
  
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
  
      .userAdminArticleHeading {
        border-bottom: 1px solid #e3e3e3;
      }
  
      .history-cart {
        position: relative;
        margin-top: 40px;
        padding-top: 40px;
        text-align: center;
        color: #fff;
        margin-bottom: 0px;
        height: 182px;
  
        .dashboard-dollar-icons {
          background: #ab2c89;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
  
          svg {
            margin: auto;
            color: #fff;
            font-size: 30px;
          }
  
          &.transaction-icon {
            background-color: #03cd84;
          }
  
          &.average-transaction-icon {
            background-color: #ff8743;
          }
  
          &::before {
            content: "";
            // outline: #fff solid 1px;
            position: absolute;
            border-radius: 50%;
            height: 106%;
            width: 106%;
            left: -3%;
            opacity: 0.5;
          }
        }
  
        h5 {
          font-size: 18px;
          font-weight: 400;
  
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 13px;
          }
        }
  
        p {
          font-size: 28px;
          font-weight: 600;
  
          @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 24px;
          }
  
          &.selected-date {
            line-height: 0;
  
            .history-card-date {
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
  
              svg {
                margin-right: 5px;
              }
            }
          }
        }
  
        &.donation {
          background: #802367;
        }
  
        &.transaction {
          background: #04b977;
        }
  
        &.average-transaction {
          background: #e26a38;
        }
      }
  
      .history_button {
        .btn {
          width: 100%;
          font-size: 14px;
          color: #000;
          text-decoration: none;
          border-radius: 8px;
          padding: 14px;
          margin-bottom: 10px;
  
          &.btn-grant {
            border: 1px solid #a62f86;
          }
  
          &.btn-link {
            border: 1px solid #e26a38;
          }
  
          &.btn-success {
            background: #03cd84;
            border: 1px solid #03cd84;
          }
        }
      }
  
      .newThemeTable {
        margin-top: 15px;
  
        thead {
          background: rgba(0, 181, 116, 0.35);
          outline: none;
  
          tr {
            th {
              border-bottom: none;
              font-weight: 600 !important;
              font-size: 16px;
            }
          }
        }
  
        tbody {
          tr {
            td {
              a {
                color: #000;
              }
            }
          }
        }
      }
  
      .funding_bg {
        background: #fff1eb;
        border-radius: 15px 15px 0px 0px;
        padding: 24px;
  
        label {
          font-weight: 500;
        }
  
        .css-b62m3t-container {
          width: 50%;
  
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
  
        .select__control.css-13cymwt-control {
          background-color: #fff;
          border: 1px solid #f0d3c6;
          height: 39px;
        }
      }
  
      .PrevettedData {
        background-color: #fdfdfd;
  
        .card_prevetted {
          background-color: #fbf8f8;
          box-shadow: none !important;
          border-radius: 0;
  
          .card_prevetted_left {
            background-color: #e8e8e8;
            height: 100%;
            width: 100%;
            display: flex;
  
            img {
              margin: auto;
            }
          }
  
          .cultural-preservation {
            hr {
              border: 1px solid #e7e7e7;
              margin: 0.5rem 0;
            }
  
            .content-align {
              span {
                width: 24px;
                display: inline-block;
  
                svg {
                  width: 22px;
                  height: 22px;
                  color: #893974;
                }
              }
            }
          }
  
          .card_prevetted_right {
            background-color: #fcf0eb;
            height: 100%;
            width: 100%;
            display: flex;
  
            .orange-button {
              color: #fff;
              height: 42px;
  
              @media screen and (min-width: 768px) and (max-width: 1231px) {
                height: auto;
              }
            }
          }
        }
      }
  
      .react-tel-input .form-control {
        width: 100%;
      }
  
      .pagination {
        .page-item {
          margin: 0 4px;
  
          @media screen and (max-width: 767px) {
            margin: 0 1px;
          }
  
          .page-link {
            width: 38px;
            border-radius: 50%;
            text-align: center;
            background: #f7f4f4;
            border: 1px solid #e26a38;
          }
  
          &.active {
            .page-link {
              background: #e26a38;
              color: #fff;
              height: 38px;
              width: 38px;
            }
          }
  
          &:first-child,
          &:last-child {
            .page-link {
              background: transparent;
              border-color: transparent;
              // color: #e26a38;
              font-weight: 500;
            }
          }
        }
      }
  
      .recommend-grants-form {
        background: #fdfdfd;
  
        .form-check-input {
          border: 4px solid rgba(248, 157, 119, 0.59);
          height: 16px;
        }
  
        .form-check-input:checked {
          border: 1px solid rgba(248, 157, 119, 0.59);
          background-color: #e26a38;
          border-color: #e26a38;
  
          &:focus,
          &:hover {
            box-shadow: 0 0 0 0.25rem rgba(248, 157, 119, 0.59);
          }
        }
  
        input {
          height: 42px;
        }
  
        .css-13cymwt-control {
          input {
            height: 30px;
          }
        }
  
        .add-more-grants {
          background: #fbf8f8;
          border: 1px solid #efefef;
          box-shadow: 0px 0.2px 0.1px 1px rgba(0, 0, 0, 0.02);
          position: relative;
          // cursor: pointer;
  
          .close {
            position: absolute;
            right: 15px;
            top: 8px;
            width: 30px;
            height: 30px;
            background: #f4aa8a;
            border-radius: 50%;
            padding: 8px;
            z-index: 1;
  
            path {
              stroke: #e26a38;
            }
          }
        }
  
        .css-b62m3t-container {
          .organisation-selection__clear-indicator {
            background-color: #7695a2;
            border-radius: 50%;
  
            svg {
              color: #fff;
            }
          }
        }
  
        .organisation-selection__control--is-focused,
        .select__control--is-focused {
          height: 44px;
  
          .organisation-selection__value-container,
          .select__value-container {
            height: 44px;
            position: relative;
            top: -5px;
          }
  
          .organisation-selection__input-container,
          .select__input-container {
            height: 44px;
          }
        }
      }
    }
  
    .organisation-selection__menu,
    .select__menu {
      input,
      option,
      span,
      div {
        cursor: pointer;
      }
    }
  
    .recommend-wrapper {
      background-color: #eee3dfa1;
      padding: 30px;
  
      .recommend-image {
        background-color: #00b574;
        padding: 20px;
        height: 100%;
        display: flex;
  
        img {
          margin: auto;
        }
      }
  
      .recommend-details-wrapper {
        height: 100%;
        // display: flex;
  
        .recommend-details {
          margin: auto 0;
          padding: 20px;
          text-align: left;
  
          @media screen and (max-width: 767px) {
            margin: auto;
            text-align: center;
          }
        }
      }
    }
  }
  
  // userAdminPanel
  
  // Footer
  .frontend-footer {
    background-color: #00344b;
    color: #fff;
  
    h6 {
      text-transform: uppercase;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 400;
  
      li {
        margin-bottom: 0.5rem !important;
  
        a {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
        }
      }
  
      &.social_media {
        li {
          float: left;
          padding-right: 2%;
          margin-bottom: 10% !important;
  
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            margin-bottom: 20px !important;
          }
  
          a {
            background: #012a3c;
            border: 1px solid #02618b;
            transform: matrix(1, 0, 0, -1, 0, 0);
            border-radius: 50%;
            padding: 9px 10px;
  
            &:hover {
              background-color: #001f2d;
            }
          }
        }
      }
    }
  
    .nav-logo {
      text-align: center;
      display: block;
      margin-bottom: 30px;
  
      @media screen and (max-width: 767px) {
        text-align: left;
        margin-top: 20px;
      }
  
      img {
        height: 50px;
      }
    }
  
    .policy {
      float: right;
    }
  
    .footer-bottom {
      background-color: #022839;
    }
  }
  
  // Footer
  
  // Modal
  .user_modal {
    .modal-header {
      background-color: #e26a38;
      color: #fff;
  
      @media screen and (max-width: 991px) {
        padding: 1rem !important;
      }
  
      .btn-close {
        opacity: 1;
        background: url(../../../public/assets/close.png) center center no-repeat;
      }
    }
  
    .modal-body {
      .nomination-card {
        border: 1px solid #e7dede;
        border-radius: 10px;
        margin-bottom: 10px;
      }
  
      .orange-border {
        border: #e26a38 solid 1px;
      }
  
      .form-group {
        border-bottom: #dfdfdf solid 1px;
  
        .quarter-box {
          border: none;
          color: #000000;
          border-radius: 8px 8px 0px 0px;
          padding: 14px 34px;
        }
  
        .btn-check:checked + .btn {
          background-color: #e26a38;
          color: #fff;
        }
  
        .btn-check:focus + .btn-outline-info,
        .btn-outline-info:focus {
          box-shadow: none !important;
        }
      }
  
      .nomination-card {
        .nomination-title {
          border-bottom: 1px solid #dfdfdf;
          color: #e26a38;
          font-size: 18px;
          font-weight: 600;
          line-height: 21px;
          padding-bottom: 15px;
        }
  
        .nomination-box {
          background: #f7f7f7;
          border-radius: 2px;
          padding: 12px;
        }
  
        &.nomination-card-secondary {
          background: rgba(217, 217, 217, 0.05);
          border: 1px solid rgba(217, 217, 217, 0.5);
          border-radius: 10px 10px 0px 0px;
          // padding: 4px 8px;
          margin-bottom: 20px;
  
          .nomination-title {
            color: #e26a38;
          }
        }
      }
  
      .quarterly-reports-box {
        .card {
          position: relative;
          background-color: transparent;
          box-shadow: none !important;
  
          .card-body {
            background: rgba(232, 228, 227, 0.2);
            border: 1px solid #bababa;
            border-radius: 10px;
            --bs-card-border-width: 0;
            --bs-card-border-color: none;
            display: flex;
            padding: 40px 18px;
  
            svg {
              margin: auto;
              color: #e26a38;
              width: 100px;
              height: 70px;
            }
          }
  
          .card-footer {
            margin-top: -36px;
            background-color: transparent;
            border-top: none;
  
            .download-button {
              background: #e26a38;
              border: 2px solid #ffffff;
              border-radius: 50%;
              flex: 1;
              height: 55px;
  
              svg {
                font-size: 20px;
              }
            }
  
            h6 {
              font-weight: 400;
            }
          }
        }
  
        &.quarterly-reports-box-secondary {
          .card {
            .card-footer {
              position: absolute;
              bottom: 0%;
              left: 50%;
              transform: translate(-50%, 45%);
  
              .download-button {
                height: 50px;
                display: flex;
  
                svg {
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .new-grantee-modal {
    .form-check-input {
      border: 4px solid rgba(248, 157, 119, 0.59);
    }
  
    .form-check-input:checked {
      border: 1px solid rgba(248, 157, 119, 0.59);
      background-color: #e26a38;
      border-color: #e26a38;
  
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.25rem rgba(248, 157, 119, 0.59);
      }
    }
  }
  
  .new-faqs-modal {
    .form-check-input {
      border: 4px solid rgba(248, 157, 119, 0.59);
      border-radius: 0px !important;
    }
  
    .form-check-input:checked {
      border-radius: 0px !important;
      border: 1px solid rgba(248, 157, 119, 0.59);
      background-color: #e26a38;
      border-color: #e26a38;
  
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.25rem rgba(248, 157, 119, 0.59);
      }
    }
  }
  
  .details_modal {
    .details_modal_quarerly {
      border-bottom: #dfdfdf solid 1px;
  
      .quarter-box {
        border: none;
        color: #000000;
        border-radius: 8px 8px 0px 0px;
        padding: 14px 22px;
      }
  
      .btn-check:checked + .btn {
        background-color: #e26a38;
        color: #fff;
      }
  
      .btn-check + .btn:hover {
        color: #000;
      }
  
      .btn-check:focus + .btn-outline-info,
      .btn-outline-info:focus {
        box-shadow: none !important;
      }
    }
  }
  
  .signup-form {
    .phone-input,
    .react-tel-input {
      .form-control {
        width: 100% !important;
      }
    }
  
    .checkbox_form_group {
      @media screen and (min-width: 768px) and (max-width: 991px) {
        line-break: anywhere;
      }
  
      .form-check-input {
        border-radius: 0.25em !important;
  
        &:checked {
          background-color: #e26a38;
          border-color: #e26a38;
        }
      }
    }
  }
  
  // Modal
  
  // button
  .eventModalButton {
    padding: 0px !important;
    text-align: left !important;
    height: 49px;
  
    span {
      background-color: rgb(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 6px 0 0 6px;
      margin-right: 15px;
  
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  
    &.wire {
      background-color: #12a8c9;
    }
  
    &.donate {
      background-color: #03cd84;
  
      &:hover {
        background-color: #23e09b;
      }
    }
  
    &.gift {
      background-color: #ab2c89;
  
      &:hover {
        background-color: #df5bbb;
      }
    }
  }
  
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100px;
  }
  
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
  
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }
  
  #recommend_grant_button {
    background-color: #00b574 !important;
  }
  
  // button
  
  // Admin
  
  .admin-body-layout {
    .mitizwrapper {
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  
    .dashbord_container {
      .support_aside {
        .justify-content-between {
          @media screen and (max-width: 575px) {
            width: max-content;
            margin: auto;
          }
        }
  
        .history-cart {
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            margin-bottom: 20px;
          }
  
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
  
          .card {
            margin-bottom: 0;
  
            .card-title {
              @media screen and (min-width: 768px) and (max-width: 991px) {
                font-size: 11px;
              }
            }
          }
        }
      }
  
      .submission-chart {
        .chart {
          .chart_data {
            height: 168px;
  
            @media screen and (max-width: 991px) {
              height: 158px;
            }
  
            canvas {
              margin: auto;
              width: 100% !important;
            }
          }
        }
      }
  
      .recent_donors {
        height: 515px;
  
        @media screen and (max-width: 767px) {
          height: auto;
        }
  
        .view-more-btn {
          &:hover {
            background-color: #e26a38;
            border-color: #e26a38 !important;
          }
        }
  
        .newThemeTable {
          tbody {
            tr {
              td {
                .recent_dmf_date {
                  display: block;
                  width: 122px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  
    .search_button {
      @media screen and (min-width: 992px) and (max-width: 996px) {
        padding-left: 12px;
        padding-right: 11px;
      }
  
      @media screen and (min-width: 768px) and (max-width: 772px) {
        padding-left: 12px;
        padding-right: 11px;
      }
    }
  }
  
  .admin-aside-modification {
    .support_aside {
      .admin-nav {
        .nav-item {
          .nav-link {
            &::before {
              margin: auto;
              display: block;
              width: 26px;
              height: 26px;
              content: "";
              background: url(../../../public/assets/seedingIcon.svg) no-repeat;
              background-size: auto;
              background-position: left top;
            }
  
            &.active {
              &::before {
                background-position: 93% top;
              }
            }
          }
  
          &:nth-child(2) {
            .nav-link {
              &::before {
                background-position: left 12%;
              }
  
              &.active {
                &::before {
                  background-position: 93% 12%;
                }
              }
            }
          }
  
          &:nth-child(3) {
            .nav-link {
              &::before {
                background-position: left 24%;
                width: 31px;
                height: 32px;
              }
  
              &.active {
                &::before {
                  background-position: 104% 24%;
                }
              }
            }
          }
  
          &:nth-child(4) {
            .nav-link {
              &::before {
                background-position: left 40%;
              }
  
              &.active {
                &::before {
                  background-position: 92% 40%;
                }
              }
            }
          }
  
          &:nth-child(5) {
            .nav-link {
              &::before {
                background-position: left 52%;
                width: 29px;
              }
  
              &.active {
                &::before {
                  background-position: 93% 52%;
                  width: 28px;
                }
              }
            }
          }
  
          &:nth-child(6) {
            .nav-link {
              &::before {
                background-position: left 65%;
                width: 28px;
              }
  
              &.active {
                &::before {
                  background-position: 93% 65%;
                }
              }
            }
          }
  
          &:nth-child(7) {
            .nav-link {
              &::before {
                background-position: left 76%;
                width: 28px;
                height: 22px;
              }
  
              &.active {
                &::before {
                  background-position: 93% 76%;
                }
              }
            }
          }
  
          &:nth-child(8) {
            .nav-link {
              &::before {
                background-position: 5% 88%;
              }
  
              &.active {
                &::before {
                  background-position: 93% 88.5%;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .admin {
    .modal-header {
      @media screen and (max-width: 991px) {
        width: 100%;
      }
  
      .btn-close {
        @media screen and (max-width: 991px) {
          top: 15px;
          right: 15px;
        }
      }
    }
  
    .modal-body {
      padding: 15px 15px !important;
    }
  }
  
  .admin-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    padding: 0 25px;
  
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
  
  // Admin
  
  .spkeperson_text {
    color: #00344b;
    font-size: 18px;
    font-weight: 400;
  }
  
  .profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #e26a38;
    font-weight: bold;
    color: #fff;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
  }
  
  .cancel_modal {
    background-color: #e1e1e0 !important;
    color: #000 !important;
    border: none !important;
  }
  
  .submit-Id-button {
    background-color: #00bf87 !important;
    border: none !important;
  }
  
  .profileImage_sidebar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #e26a38;
    font-weight: bold;
    color: #fff;
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin: auto;
    margin-bottom: 15px;
    align-items: center;
    box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.17);
  }
  
  .signin-form input {
    padding-left: 35px;
  }
  
  .update_donor_details .react-tel-input .form-control {
    width: 233px;
  }
  
  .ach_container {
    p {
      display: block;
      background: #f7f7f7;
      border-radius: 2px;
      padding: 12px;
    }
  }
  
  .footer_about_us {
    font-size: 15px;
    line-height: 1.75rem;
  }
  
  .footer_contact_us {
    font-size: 15px;
  }
  
  .footer_details {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .ein_select .select__menu {
    // visibility: hidden;
    display: none;
  }
  
  .react-tel-input {
    .country-list {
      .country-name {
        position: inherit !important;
        color: #000;
      }
  
      .dial-code {
        position: inherit !important;
      }
    }
  }
  
  // .section-body {
  //     textarea, .comment-box {
  //         margin-bottom: 42px !important;
  //     }
  // }
  .login_validation {
    position: relative;
    bottom: 15px;
  }
  
  .css-qbdosj-Input {
    cursor: pointer;
  }
  
  .prevettedtext {
    color: #00344b;
  }
  
  .otp_form {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .report_card {
    border: none !important;
  }
  
  .select__indicators .css-1wy0on6 {
    display: none !important;
  }
  
  .custom_accordian .card .card-header {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-bottom: none;
    z-index: 1;
    background-color: #eee;
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }
  
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  
  .faqs_list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .faq_text {
    margin-top: 130px !important;
    background-color: rgba(238, 227, 223, 0.631372549);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .grantee_faqs {
    margin-top: 100px !important;
    background-color: rgba(238, 227, 223, 0.631372549);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  