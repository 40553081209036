/* @import url(css/sb-admin-2.css);
@import url(css/sb-admin-2.min.css); */
/* Header */

/* @import url(./Frontend/css/frontstyle.css); */
@import url(./Backend/css/style.css);
/* @import url(./Backend/scss/backendStyle.scss); */

* {
  margin: 0;
  padding: 0;
}
.sort{
  cursor: pointer;
}
/* ._loading-overlay-transition-enter-done{
  opacity: .2 !important;
} */

.mitizcontent{
  color:black;
}
.mitizoverlay{
  background: rgb(233 220 220 / 45%) !important;
}