@font-face {
  font-family: "Beatrice", Sans-serif;
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: url('../../../public/Beatrice-Thin.eot');
  src: url('../../../public/Beatrice-Thin.eot?#iefix') format('embedded-opentype'), url('../../../public/Beatrice-Thin.woff'),
    url('../../../public/Beatrice-Thin.woff2') format('woff2'),
    url('../../../public/Beatrice-Thin.woff') format('woff');

}

body {
  background-color: #f8f9fa;
  overflow-x: hidden;
  font-family: "Work Sans", sans-serif;

}

a {
  text-decoration: none;
}

.admin {
  position: relative;
}

.title-font {
  font-weight: 700;
  color: #00344b;
  font-family: "Beatrice", Sans-serif;
}

.admin .modal-header h5 {
  font-weight: bolder;
  color: #00344b;
}

.chart_data {
  margin-top: -20px;
}

.card_header {
  font-size: 13px;
}

/* .top-background {
    background-color: #5e72e4;
    min-height: 300px;
    position: fixed;
    width: 100%;
} */
.ql-editor {
  min-height: 200px;
}

/* SideNav */
.sidenav {
  background-color: #f8f8f8;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 13rem;
  overflow-y: auto;
  padding: 0;
  box-shadow: none;
  z-index: 1;
  /* box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15); */
  border: 1px solid #e1e1e1;
  left: 0;
  /* margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem; */
  /* border-radius: 0.4rem; */
}

.sidenav-header {
  height: 4.875rem;
  text-align: center;
}

.brand-logo img {
  height: 50px;
  margin: 1.5rem 2rem;
}

.sidenav .navbar-nav .nav-item {
  padding: 0;
  line-height: inherit;
}

.sidenav .navbar-nav .nav-link {
  margin: 0 0.04rem;
  padding: 0.675rem 1rem;
  font-weight: 400;
  color: #67748e;
  cursor: pointer;
}

.sidenav .navbar-nav .nav-link:focus,
.sidenav .navbar-nav .nav-link:active,
.sidenav .navbar-nav .nav-link.active,
.sidenav .navbar-nav .nav-link:hover {
  font-weight: 600;
  color: #fff;
  background-color: #3142a1;
  border: 0.01 solid #3142a1;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.sidenav .navbar-nav .nav-link svg {
  margin-right: 0.5rem;
  position: relative;
  top: -2px;
  font-size: 24px;
}

.custom-scroll {
  overflow: auto !important;
  width: 103%;
}
.custom-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.custom-scroll::-webkit-scrollbar{
  visibility: hidden;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #67748e;
}

.sidenav::-webkit-scrollbar {
  width: 6px;
}

.sidenav::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidenav::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

.sidenav::-webkit-scrollbar-thumb:hover {
  background: #67748e;
}

.sidebar-closing {
  display: none;
}

.admin-aside-modification .support_aside {
  padding: 20px 0;
}

.admin-aside-modification .support_aside .nav-tabs {
  border-bottom: none;
}

.admin-aside-modification .support_aside .nav-item {
  border-bottom: none;
  padding: 15px 0;
  line-height: inherit;
  display: block;
  width: 100%;
}

.admin-aside-modification .support_aside .nav-item .nav-link {
  text-align: center;
  font-weight: 800 !important;
  position: relative;
  color: #00344B;
  border: none !important;
  font-size: 15px;
  font-family: "Beatrice", Sans-serif;
}

.admin-aside-modification .support_aside .nav-item .nav-link:hover {
  background-color: transparent !important;
}

.admin-aside-modification .support_aside .nav-item .nav-link.active {
  background-color: transparent !important;
  color: #e26a38 !important;
  border: none !important;
}

/* .admin-aside-modification .support_aside .nav-item .nav-link.active::after {
  background-color: #e26a38;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 12%;
  top: 30%;
} */

.admin-aside-modification .support_aside .nav-item .nav-link.active::after {
  /* background-color: #e26a38; */
  width: 42px;
  height: 42px;
  display: block;
  /* border-radius: 50%; */
  content: "";
  position: absolute;
  left: 0%;
  top: 10%;
  background: url(../../../public/assets/circle.png) left top no-repeat;
}

.admin-aside-modification .support_aside .nav-item .nav-link svg {
  display: block;
  margin: auto;
  margin-bottom: 8px;
  width: 20px;
  height: 20px;
}

.admin-aside-modification .support_aside .nav-item .nav-link.active svg {
  color: #e26a38 !important;
}

@media screen and (min-width: 992px) {
  .sidenav .navbar-collapse {
    display: block !important;
  }
}

@media screen and (max-width: 991px) {
  .sidenav {
    transform: translateX(-17.125rem);
    transition: all 1s ease;
  }

  .sidenav hr {
    margin-top: 0;
  }

  .sidenav.mobile-sideView {
    margin-left: 0;
    transform: translateX(0);
    transition: all 1s ease;
    z-index: 2;
  }

  .brand-logo {
    position: relative;
  }

  .brand-logo img {
    margin: 1rem 2rem;
  }

  .sidebar-closing {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 30px;
    opacity: 0.7;
  }
}

.nav-icon-color {
  color: #00344b !important;
}

/* SideNav */

/* Main */
main {
  /* position: absolute; */
  top: 0;
  width: -webkit-fill-available;
  margin-left: 13rem;
  transition: all 0.2s ease-in-out;
}

main .admin-navbar {
  margin: 0.8rem 1.5rem;
}

main .admin-navbar .nav-item {
  list-style: none;
  padding-right: 0px;
}

main .admin-navbar .btn-group .btn {
  background: transparent;
  border: none;
  display: flex;
  justify-content: right;
  align-items: center;
  color: #000;
}

main .admin-navbar .btn-group .btn:focus {
  box-shadow: none;
}

main .admin-navbar .btn-group .btn img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.admin-navbar .dropdown-item {
  line-height: 20px;
}

.sidenav-toggler-inner {
  display: none;
}

.sidenav-toggler-inner svg {
  font-size: 30px;
  color: #000 !important;
  /* color: #fff; */
  /* margin-left: 15px; */
}

main .admin-body-layout {
  min-height: 71vh;
  padding: 0 15px 30px;
}

/* Dahsboard Table */

.admin-body-layout .newThemeTable {
  margin-bottom: 0;
}

.admin-body-layout .newThemeTable>thead {
  outline: #c5c5c5 solid 1px;
}

.admin-body-layout .newThemeTable thead tr th,
.admin-body-layout .newThemeTable tbody tr td {
  padding: 20px;
  color: #595858;
  font-weight: 300;
}

.admin-body-layout .recentDonorTable tbody tr td {
  padding: 10px !important;
  color: #595858;
  font-weight: 300;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #fff;
}

.admin-body-layout .newThemeTable thead tr th {
  color: #212529;
  font-weight: 600;
}

.admin-body-layout .newThemeTable thead tr th svg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.admin-body-layout .newThemeTable tbody tr td a {
  color: #595858;
  font-weight: 300;
}

/* .admin-body-layout
  .newThemeTable
  tbody
  tr:last-child
  td:last-child
  .modal_Dropdown
  .dropdown-menu {
  height: 144px;
  height: max-content;
  transform: translate3d(0px, -181px, 0px) !important;
} */

.admin-body-layout .newThemeTable tbody tr:last-child td:last-child .modal_Dropdown .dropdown-menu::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid #fff;
  border-bottom: none;
  bottom: -12px;
  top: initial;
  transform: rotate(120deg);
  right: 6px;
}

.admin-body-layout .newThemeTable tbody tr:nth-last-child(3) td:last-child .modal_Dropdown .dropdown-menu::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid #fff;
  border-bottom: none;
  bottom: -8px;
  top: initial;
  transform: rotate(120deg);
  right: 10px;
}

.admin-body-layout .newThemeTable tbody tr:nth-last-child(2) td:last-child .modal_Dropdown .dropdown-menu::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid #fff;
  border-bottom: none;
  bottom: -8px;
  top: initial;
  transform: rotate(120deg);
  right: 10px;
}


.modal_Dropdown .dropdown_btn {
  /* background-color: transparent; */
  /* border: none; */
  color: #00344b;
  /* padding: 0; */
  /* line-height: 0;
    font-size: 22px; */
  background-color: transparent;
  border: none;
}

.modal_Dropdown .dropdown_btn:hover,
.modal_Dropdown.show .dropdown_btn:hover,
.modal_Dropdown.show .dropdown_btn:active,
.modal_Dropdown.show .dropdown_btn.active,
.modal_Dropdown.show .dropdown_btn:focus {
  background-color: transparent !important;
  color: #000;
  box-shadow: none !important;
  outline: none;
}

.modal_Dropdown .btn:first-child:active:focus-visible {
  background-color: transparent;
  color: #000;
  box-shadow: none !important;
  outline: none;
}

.modal_Dropdown .dropdown-menu {
  position: relative;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
  border: none;
}

.modal_Dropdown .dropdown-menu::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  right: 10px;
  top: -10px;
}

.modal_Dropdown .dropdown-item {
  color: #595858;
  font-size: 14px;
  padding: 10px 15px;
}

.donor_icon {
  cursor: pointer;
  color: rgb(226, 106, 56);
  border: rgb(226, 106, 56) solid 1px;
  border-radius: 30px;
  width: 100%;
  width: 100px;
  padding: 4px;
  height: 28px;
}

/* Dahsboard Table */

/* Dahsboard Modal */

.admin .modal-header {
  position: relative;
  width: 62%;
  margin: auto;
  border-bottom: none !important;
  padding: 25px 0 10px !important;
}

.admin .modal-title {
  margin: auto;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 26px;
}

.admin .modal-header .btn-close {
  border: #595858 solid 1px;
  border-radius: 30px;
  position: absolute;
  /* right: -131px; */
  right: -70px;
  /* new added */
  top: 21px;
  width: 60px;
  background-position: 25% 50%;
  background-size: 10px;
  font-size: 10px;
}

.admin .modal-header .btn-close::after {
  content: "close";
  position: absolute;
  top: 3px;
  left: 40%;
  font-size: 13px;
}

.admin .modal-body {
  padding: 15px 40px !important;
}

.admin .modal-body .form-label,
.admin .modal-body .form-control,
.admin .modal-body .form-select,
.admin .modal-body .css-6j8wv5-Input {
  color: #595858;
  font-size: 14px;
}

.modal-body .btn,
.modal-footer .btn {
  /* width: 100%; */
  font-size: 15px;
}

.admin .modal-footer {
  border-top: none;
}


/* Dahsboard Modal */

@media screen and (max-width: 991px) {
  main {
    margin-left: 0;
  }

  main .admin-navbar {
    margin: 0.8rem 0;
  }

  main .admin-navbar .nav-item:last-child {
    padding-top: 0px;
    line-height: 0;
    margin-top: -18px;
    padding-bottom: 0;
  }

  .blur-layout {
    opacity: 0.1;
    background-color: #000;
  }

  .sidenav-toggler-inner {
    display: block;
  }

  .modal-header {
    width: 100%;
    padding: 48px 15px 0px !important;
  }

  .modal-body {
    padding: 30px 15px !important;
  }
}

/* Main */

/* Footer */
.copyright {
  font-size: 14px;
  padding: 15px 0;
  font-weight: 500;
}

/* Table */
.table>thead {
  outline: #c5c5c5 solid 1px;
}

.table thead tr th {
  border-bottom-color: snow;
  font-size: 18px;
}

/* Table */
/* CkEditor5 */


.e-header-icon-wrapper .e-delete,
.e-event-popup .e-popup-content,
.e-time {
  display: none !important;
}

.e-header-icon-wrapper,
.e-event-popup {
  display: none !important;
}

.e-appointment-wrapper [aria-label*="DMF"] {
  background-color: #23893e !important;
}

.e-popup-header {
  background-color: #23893e;
}

/* .history-cart h5 {
  cursor: pointer;
} */

table table table td p {
  padding-bottom: 0.2rem !important;
}

table table table td p br {
  display: none !important;
}

table table table td p:empty {
  display: none !important;
}

.admin .nomination-card {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 5px #ccc;
}

.admin .nomination-title {
  background-color: #6d79b9 !important;
  color: white;
  padding: 10px 0;
  text-indent: 12px;
  font-weight: 600;
}

.customise_table {
  border: 1px solid rgb(185, 185, 185);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

/* .submission_table{
} */

.nomination_tab {
  cursor: pointer;
  padding: 10px;
  color: #000;
  margin-left: -16px;
}

.active_nomination_tab {
  color: #5e72e4;
  border: 1px solid rgb(181, 181, 181);
  border-bottom: none;
  padding: 10px;
  max-height: auto;
  margin-bottom: 10px;
  background-color: white;
}

.nav-tabs {
  --bs-nav-tabs-border-radius: 0rem !important;
}

.grant_tab,
.active_grant_tab {
  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #000;
  background-color: white;
  padding: 0px 0px;
  text-indent: 10px;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.grant_tab h5,
.active_grant_tab h5 {
  padding: 0;
  margin: 0;
  text-indent: 0;
  color: #00344B;
  font-weight: 700;
  font-size: 20px;
}

.active_grant_tab h5 {
  color: #00344B;
}

.active_grant_tab {
  border: 1px solid rgb(185, 185, 185);
  border-bottom-color: white !important;
  cursor: default !important;
}
@media screen and (max-width: 650px) {
  .active_grant_tab {
    /* border-bottom-color: rgb(185, 185, 185) !important;
    border-radius: 6px !important; */
  }

  .customise_table {
    border-radius: 6px !important;
  }
}

@media screen and (max-width: 650px) {
  .grant_customise_table {
    border-radius: 0 !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}

@media screen and (max-width: 1100px) {

  .grant_tab h5,
  .active_grant_tab h5 {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .active_grant_tab {
    /* border-bottom-color: white !important; */
    cursor: default !important;
  }
}

.border-r6 {
  border-radius: 6px !important;
}

.admin .nav-link {
  padding: 0.1rem 1.2rem;
}

table tbody>tr:last-child>td {
  border-bottom: 0;
}

.table thead>tr:last-child>th {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #00344B;
}

/* Customised Calender */

.select-menu,
css-2l3qy {
  display: none;
}

.chart {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 1px 5px 1px;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.01),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0) !important;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.01),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0) !important;
}

.card .percentage {
  height: 22px;
}

.nomination .percentage span,
.nomination-bar,
.nomination-bar:hover {
  color: #e26a38;
}

.submission .percentage span,
.submission-bar,
.submission-bar:hover {
  color: #802367;
}

.nomination2 .percentage span,
.nomination2-bar,
.nomination2-bar:hover {
  color: #00b574;
}

.submission2 .percentage span,
.submission2-bar,
.submission2-bar:hover {
  color: #000000;
}

.view-more {
  position: relative;
}

.nomination-bar::before,
.submission-bar::before,
.nomination2-bar::before,
.submission2-bar::before {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 90px;
  height: 3px;
  background-color: #000;
  bottom: -18px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.nomination-bar::before {
  background-color: #e26a38;
}

.submission-bar::before {
  background-color: #802367;
}

.nomination2-bar::before {
  background-color: #00b574;
}

.dropdown-picker {
  border: 1px solid #212832 !important;
  border-radius: 30px !important;
  text-align: end;
}

.dropdown-picker:focus {
  box-shadow: none;
}

.daterangepicker.opensright:before {
  right: 44px;
  left: inherit !important;
}

.daterangepicker.opensright:after {
  right: 45px;
  left: inherit !important;
}

.pagination .page-link {
  color: #212832;
  border: none;
  border: #fff solid 1px;
}

.pagination .page-link:hover,
.pagination .page-link:focus {
  background-color: transparent;
  color: #e26a38;
  border-color: transparent;
  box-shadow: none;
}

.pagination .page-item.active .page-link {
  z-index: 3;
  color: #e26a38;
  border: #e26a38 solid 1px;
  background-color: #fff;
  border-radius: 6px;
}

.pagination .page-item:first-child .page-link {
  border: none;
}

@media screen and (max-width: 767px) {
  main .admin-body-layout {
    padding: 0;
  }

  .card .percentage {
    height: auto;
  }
}

/* Recent DMF donor */

.recent_donor_font {
  font-weight: 600;
}

.view-more-btn {
  border-color: #00344B !important;

}

/* Sidebar font */

.nav-font {
  /* font-family: "Beatrice"; */
  color: #403d3d;
  font-weight: 700 !important;
  font-size: 15px;
}

/* New Calender */

/* .recent_donors {
  min-height: 516px;
  max-height: 516px;
  overflow-y: scroll;
} */

/* .recent-donor-modal {
  margin-right: 650px !important;
} */

@media screen and (max-width: 767px) {
  .recent_donors {
    min-height: inherit;
    max-height: inherit;
  }
}

/* modal */
/* React Select */
.css-1xc3v61-indicatorContainer {
  margin: 5px 6px 7px 5px !important;
  padding: 5px !important;
}

.css-1u9des2-indicatorSeparator {
  margin: 5px 0 5px 0 !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 5px !important;
  margin-right: 5px !important;
}


.select__control .select__multi-value {
  background-color: hsl(0, 0%, 100%);
  border-radius: 30px;
  padding: 4px 10px;
  border: #c5c3c3 solid 1px;
}

.select__control .select__multi-value__remove {
  padding: 4px !important;
  height: 20px;
  width: 20px;
  margin-top: 3px;
}

.select__control .select__multi-value__remove,
.select__clear-indicator {
  background-color: #7695a2;
  border-radius: 50%;
  padding: 5px 6px;
  margin-left: 10px;
}

.select__control .select__multi-value__remove svg,
.select__clear-indicator svg {
  color: #fff;
}


/* dropdown remove icon */
.remove-icon {
  color: #E26A38;
}

.edit-icon {
  color: #00344B;
}

.details-icon {
  color: #00344B;
}

/* Sub-menu-font */
#sub-menu-font {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

}

@media screen and (min-width: 992px) and (max-width:1147px) {
  #sub-menu-font {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }

  #sub-menu-font svg {
    margin-right: 2px;
  }
}

/* Tooltip styles */
.react-tooltip {
  font-size: 14px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 8px;
}

.fc .fc-daygrid-day-frame {
  position: relative;
}

.fc-daygrid-day-events {
  position: absolute !important;
  top: 10px;
  left: 10px;
}

.fc-event {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 5px
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: #08344B;
  text-decoration: none;
}

.admin-calender-customised {
  background-color: #F4EDE6;
  border-radius: 10px;
}

.fc-daygrid-day-number {
  color: #000;
  text-decoration: none;
}

.fc-next-button,
.fc-prev-button {
  background: none !important;
  color: black !important;
  border: none !important;
}

.fc .fc-toolbar-title {
  color: #08344B;
  font-size: 16px;
  margin: 0;
  margin-right: auto;
  line-height: 5px;
  margin-right: 97px;
  line-height: 14px;
}

@media screen and (min-width: 1193px) and (max-width: 1322px) {
  .fc .fc-toolbar-title {
    font-size: 12px;
    margin-right: 86px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1192px) {
  .fc .fc-toolbar-title {
    font-size: 10px;
    margin-right: 86px;
  }
}


.fc .fc-daygrid-day.fc-day-today {
  background-color: #E26A38;
  border-radius: 10px;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: white;
}

/* Search header */
.collapse show,
.card-header {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  color: #00344B !important;
}

.breadcrumb-item a {
  color: #00344B;
}

.breadcrumb-item.active {
  width: 75%;
}

a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
  background-color: #E26A38;
  border-color: #E26A38;
  margin-left: -5px;
  cursor: pointer;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: -5px;
  cursor: pointer;
}

.calendar {
  position: relative;
  padding-right: 10px;
}

.calender_sorting {
  position: absolute;
  right: 0;
}

.fc .fc-scrollgrid-liquid {
  border: none;
}

.calendar .fc-toolbar-title {
  margin-top: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1322px) {
  .calendar .fc-toolbar-title {
    margin-top: 12px;
  }
}

.fc .fc-button .fc-icon {
  margin-top: 11px;
}

.admin-calender-customised .calendar .calender_sorting {
  text-align: right;
  border-radius: 10px !important;
  padding: 15px;
  padding-bottom: 0;
}

.admin-calender-customised .calendar .calender_sorting select:focus-visible {
  outline: none;
}

.admin-calender-customised .calendar .calender_sorting span {
  margin-right: 10px;
}

.admin-calender-customised .calendar .calender_sorting #calendar-view-select {
  background-color: transparent;
  /* border-radius: 30px; */
  padding: 3px 6px;
  border: none;
}

/* Setting */
.admin-body-layout .support_aside .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
  width: 100%;
}

.admin-body-layout .support_aside .nav-link {
  text-align: left;
  font-weight: 400;
  color: #00344B;
  border: none !important;
  font-size: 12px;
  cursor: pointer;
}

.admin-body-layout .support_aside .nav-link svg {
  margin-right: 5px;
  vertical-align: sub;
  /* color: #c5ccd6; */
}

.admin-body-layout .support_aside .nav-link.active {
  background-color: #E26A38 !important;
  color: #fff !important;
  border-radius: 0;
}

/* Setting */
#badge_font {
  font-size: 10px !important;
}

/* Sorting Icon */


/*sorting starts*/
.sortable {
  background-image: url("../../../public/assets/sortable.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 17px !important;
  color: #00344B;
  cursor: pointer;
}

.asc {
  background-image: url("../../../public/assets/asc.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 17px !important;
  color: #00344B;
  cursor: pointer;
}

.desc {
  background-image: url("../../../public/assets/desc.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 17px !important;
  color: #00344B;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.dmf_edit_modal {
  line-height: 40px;
  color: #fff;
  font-weight: 500;
  /* padding: 0.5rem 1rem; */
}
.grant_tab .nav-item {
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.active_grant_tab .nav-item {
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.details_modal .modal-header .btn-close {
  opacity: 1;
  background: url(../../../public/assets/close.png) center center no-repeat;
}

.details_modal .modal-header {
  background-color: #E26A38;
  color: #fff;
}

.nomination-card {
  border: 1px solid #e7dede;
  border-radius: 10px;
  margin-bottom: 10px;
}

.nomination-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 15px;
  /* background-color: #426879; */
  color: #E26A38;

}

.nomination-box {
  background: #F7F7F7;
  border-radius: 2px;
  padding: 12px;
}

/* RECENT DMF DONOR */
.recent_dmf_date {
  border: 1px solid #f3f3f3;
  border-radius: 50px;
  padding: 4px;
  background-color: #fbfafa;
}

.search_button {
  background-color: #f6f1eb !important;
}

.submit-Id-button {
  background-color: #00bf87 !important;
  border: none !important;
}

.cancel_modal {
  background-color: #E1E1E0 !important;
  color: #000 !important;
  border: none !important;
}

.modal_no_button {
  background-color: #f74848f0 !important;
  border: none !important;
}

.create-donor-modal .modal-header .btn-close {
  right: -74px !important;
}

.inactive .badge {
  background-color: #f74848f0 !important;
}

.active .badge {
  background-color: #00BF87 !important;
}

.search_btn {
  background-color: #f74848f0 !important;
}

.export_button {
  border-radius: 30px;
  background-color: #f87d48 !important;
  border: none !important;
  color: white;
  font-weight: 500;
}

.add_button {
  border-radius: 30px;
  background-color: rgb(3, 205, 132) !important;
  border: none !important;
  color: white;
  font-weight: 500;
}

.inactive .badge {
  background-color: #f74848f0 !important;
}

.active .badge {
  background-color: #00BF87 !important;
}

.search_btn {
  background-color: #f74848f0 !important;
}

.spoke_details_tooltip .tooltip-inner {
  min-width: 400px !important;
}

.nav_admin_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}


.tab_text {
  font-size: 17px;
  font-weight: 700;
  color: #00344b;
  cursor: pointer;

}

/* admin modal close button center */

@media screen and (max-width: 991px) {
  .modal-header {
    padding: 1rem !important;
  }
}

/* admin setting submenu */
#sub-menu-font {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  padding:15px

}
.checkbox_form_group input{
  border-radius: 4px !important;
}
.checkbox_form_group{
  line-height: 1;
  font-size: 14px;
  /* margin-top: 10px; */
}
.close-icon{
  color: red;
  font-size: 30px;
  cursor: pointer;
  position: relative;
    right: 30px;
    bottom: 25px;
}
.preview-image{
  width: 120px;
  min-width: 120px;
  height: 80px;
  min-height: 80px;
}
.image_uploader{
  border: 1px dashed gray !important;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
 }
 .image_uploader .image_click{
  color: #0c66cc
}